import { useEffect } from 'react'
import { useCookies } from 'react-cookie'

export type RightPanelType = 'colors' | 'probability' | 'manaCurve' | 'qunatities'

type RightPanelStatus = {
  open: boolean
  locked: boolean
  type: RightPanelType
}

export const useRightPanel = (): [RightPanelStatus, (updatedStatus: RightPanelStatus) => void] => {
  const [{ rpOpen, rpLock, rpType: type = 'colors' }, setCookies] = useCookies(['rpOpen', 'rpLock', 'rpType'])

  const open = rpOpen === 'true'
  const locked = rpLock === 'true'

  const handleSetNewStatus = (rightPanelStatus: RightPanelStatus) => {
    setCookies('rpOpen', `${rightPanelStatus.open}`, { path: '/' })
    setCookies('rpLock', `${rightPanelStatus.locked}`, { path: '/' })
    setCookies('rpType', rightPanelStatus.type, { path: '/' })
  }

  useEffect(() => {
    // If they locked the panel and the screen to smaller than we'd allow the locked panel to be, unlock it
    // This is a bit un-elegant since it'll trigger a re-render immediately after the inital render, but better than having a locked panel on a phone
    if (locked && window.innerWidth < 600) handleSetNewStatus({ open: false, locked: false, type })
  }, [])

  return [{ open, locked, type }, handleSetNewStatus]
}
